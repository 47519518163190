@import "../variable";
@import "../mixins";
@import "~sass-mq";
@import "../partials/footer";


.rounded-prop {
  width: 100%;
  padding: 15px;
  border: 0;
  border-radius: 22px;
}

//class for each element have rounded property

.authentication-block {
  position: relative;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%), url("../../image/authentication/shouting.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 50px 30px;
  height: 100%;

  @include mq($until: mobileLandscape) {
    padding: 30px 15px;
  }

  .evzt {
    @include mq($until: mobile) {
      #homeBlock {
        h1 {
          position: relative;
          top: 5%;
        }
      }

    }
  }

  .authentication-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    h1 {
      color: #fff;
      text-align: center;
      margin-bottom: 30px;
      font-size: 20px;
      font-weight: 700;

      @include mq($until: mobileLandscape) {
        font-size: 18px;
      }
    }

    .evzt-alert {
      max-width: 370px;
      margin: 0 auto 15px;
    }

    .right-side {
      text-align: right;
      margin-bottom: 30px;
      color: #fff;

      @include mq($until: lg-mobile) {
        text-align: center;
      }

      @include mq($until: mobileLandscape) {
        font-size: 14px;
      }

      a {
        color: $primary;
        margin-left: 5px;
      }
    }

    .left-side {
      margin-top: 30px;
      color: #fff;

      @include mq($until: lg-mobile) {
        text-align: center;
      }

      @include mq($until: mobileLandscape) {
        font-size: 14px;
      }

      a {
        color: $primary;
        margin-left: 5px;
      }
    }

    .content-auth {
      margin: auto 0;

      &-form {
        background-color: hsla(0deg, 0%, 100%, 0.5%);
        border-radius: 10px;
        width: 370px;
        margin-right: auto;
        margin-left: auto;
        padding: 40px;

        @include mq($until: mobileLandscape) {
          width: 100%;
          padding: 30px 20px;
        }

        form {
          width: 100%;

          .auth-input-form-group {
            padding-bottom: 20px;

            input {
              @extend .rounded-prop;

              &,
              &::placeholder {
                font-family: $fm-lato;
                font-size: 14px;
                font-weight: 500;
                color: $secondary;
              }

              &:hover {
                border: 0;
              }

              &:focus,
              &:active:focus {
                outline: none;
                border: none;
                box-shadow: 0 0 0 3px hsla(140deg, 45%, 52%, 0.3%);
              }
            }
          }

          &#formSignup {
            .auth-social-login {
              flex-direction: row;
            }
          }

          .auth-social-login {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 12px;
            margin-bottom: 12px;

            width: 100%;
            border-radius: 10px;

            .evzt-social-btn-container {
              display: flex;
            }

            .auth-social-form-group {
              width: min-content;
              margin: 0px;
            }

            button {
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              border: none;
              box-shadow: none !important;
              text-align: center;

              >div div:nth-child(3) {
                width: 0px !important;
                justify-content: center;
                text-align: center;
              }

              >div div:nth-child(2) {
                width: 0px !important;
              }
            }

            div {
              border: none;
            }
          }

          .submiting {
            width: 100%;
            border: none;
            border-block-end: 1px solid $light-white;
            padding-bottom: 30px;
          }

          .btn-submit {
            @extend .rounded-prop;
            @include transition(background-color);
            width: 100%;
            padding: 15px;
            background-color: $primary;
            border: 0;
            border-radius: 22px;
            color: #fff;
            cursor: pointer;
            text-align: center;
            text-transform: uppercase;
            font-family: $fm-lato;
            font-weight: 700;
            line-height: 1.27rem;

            &:not(:disabled):hover {
              background-color: #259448;
            }

            &:disabled {
              background-color: #4ea86b;
            }

            &:focus {
              box-shadow: none;
              outline: none;
            }
          }

          .customized-checkbox-block {
            margin: 0 0 20px 0;

            label {
              font-weight: 500;
            }
          }

          .auth-form-footer {
            text-align: center;
            margin-top: 12px;
            padding-top: 25px;
            font-weight: 400;

            a {
              color: inherit;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}


.evzt-mb-1 {
  margin-bottom: 1em;
}