.event-block{
  margin-top: -0.5rem;
  h2{
    margin-bottom: 10px;
  }
  .event-form-content{
    background-color: #fff;
    padding: 30px;
    .submit-event-form {
      text-align: right;
      @include mq($until: lg-mobile){
        text-align: center;
      }
      button{
        padding: 10px 5px;
        font-weight: 600;
        color: #fff;
        background: $primary;
        border-radius: 5px;
        border: 0;
        min-width: 200px;
        cursor: pointer;
      }
    }
  }
}

//list
.btn-delete-event{
  color: $danger;
}

.gallery-actiongroup{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-bottom: 25px;

  .actiongroup-gallery-button {
    display: flex;
    flex-direction: row;
    align-items: end;

    button{
      margin-right: 10px;
    }
  } 

  a{
    text-decoration: none;
  }

  a .evzt-btn.btn-white{
      display:flex;
      align-items: center !important;
      padding: 8px 19px 8px 19px;
    } 
}


@include mq($until: lg-mobile){
  .evzt-table{

    thead tr{
      display: none;
      flex-flow: column wrap;
    }

    tbody {
      display: flex;
      flex-direction: column;
      max-width: 100%;

      .guest-list-title {
        font-size: 16px;
      }

      tr {
        display: flex;
        flex-flow: column wrap; 
        padding: 12px;
        font-size: 16px;

      }
    }
  }
}


