@import "../variable";
@import "../mixins";
.evzt-card-box {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  position: relative;
  .evzt-card {
    border-radius: 10px;
    background-color: #fff;
    position: relative;
    box-shadow: 1px 4px 6px 3px rgba(0, 0, 0, 0.05);
    img{
      width: 100%;
      height: 125px;
      border-radius: 10px 10px 0 0;
      object-fit: cover;
      object-position: top;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: crisp-edges;
    }
    .evzt-card-overlay {
      @include transition();
      display: flex;
      width: 100%;
      height: 125px;
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      background: $overlay;
      border-radius: 10px 10px 0 0;
      justify-content: center;
      align-items: center;
      &:hover{
        opacity: 1;
      }
      .btn-expand {
        width: 40px;
        height: 40px;
        display: inline-flex;
        background-color: $orange;
        border-radius: 50%;
        align-items: center;
        text-align: center;
        cursor: pointer;
        justify-content: center;
        svg {
          height: 18px;
        }
      }
    }
    .evzt-card-body {
      padding: 10px 15px;
      h3{
        font-size: 18px;
      }
      p{
        font-size: 16px;
        font-weight: 500;
      }
    }
    .evzt-card-footer {
      display: flex;
      justify-content: center;
      border-top: $border;
      margin-top: 25px;
      .evzt-card-footer-action{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        max-width: 120px;
        padding: 15px 0;

        @include mq($until: lg-mobile) {
          max-width: 100%;
          justify-content: space-around;
        }

        .btn-action {
          cursor: pointer;
          width: 32px;
          height: 32px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border: 1px solid transparent;
          border-radius: 5px;
          padding: 5px;
          margin: 2px;

          &:hover{
            opacity: 0.7;
            @include transition(opacity);
            border: 1px solid #3e407e;
            border-radius: 5px;
          }
          svg {
            height: 16px;

            @include mq($until: lg-mobile) {
              height: 26px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 767px){
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (max-width: 575px){
    flex: 100%;
    max-width: 100%;
  }
}