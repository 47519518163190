.evzt-home {
  background-color: $light-white;

  .home-block,#homeBanner {
    padding: 100px 0;
    @media (max-width: 991px) {
      padding: 20px 0;
      line-height: 35px;
      p{
        text-align: justify;
      }
    }
  }

  .evzt-about {
    h1 {
      font-size: 32px;
      margin-bottom: 14px;
      line-height: 39px;
    }

    &-desc {
      flex: 1;
      padding: 0 20px 0 15px;

      p {
        margin-bottom: 15px;
      }
    }

    &-image {
      max-width: 620px;
      height: 350px;
      padding: 0 15px;

      &>img {
        border-radius: 20px;
        max-width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .home-banner {
    background-color: #e3f4f7;

    h2 {
      margin-bottom: 10px;
    }

    padding: 35px 0;
    margin-bottom: 50px;
  }

  .home-latest-events {
    padding: 20px 0;

    .evzt-home-lastest-events-images {
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      padding: 10px;
      .image-home-lastest-events {
        width: 380px;
        height: 340px;
        padding: 0 10px;
        overflow: hidden;
        border-radius: 20px;
        &:hover img{
          transform: scale(1.2);
        }
        img {
          @include transition;
          width: 100%;
          height: 100%;
          border-radius: 20px;
          object-fit: cover;
        }
      }
    }
  }
}
@include mq($until: desktop){
  #h1-sous-image{
    display: block;
    justify-content: center;
    height: auto;
    margin-bottom: 25px;
  }
  #sous-image{
    display: none;
  }
  
}
@include mq($from: desktop){
  #h1-sous-image{
    display: none;
  }
  #sous-image{
    display: block;
  }
  #def{
    display: none;
  }
}

@include mq($from: lg-mobile){
  #evzt-menu{
    width: 100%;
  }
}
@include mq($until: lg-mobile){
  #evzt-menu{
    width: 100%;
  }
}