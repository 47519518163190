//colors
$primary: #3aa65d;//set green color as a primary color
$secondary:hsla(238, 34, 37, 100%);
$default: rgb(152, 153, 176);
$dark-white: hsl(45, 14%, 95%);
$pink: hsla(343,100,78,100%);
$green: #c0e4af;
$orange: #ff5733;
$danger: #c70038;
$blue: #3769b3;
$light-white: #ffffff;
$light-green:#35975499;
//font family
$fm-lato: 'Lato', sans-serif;
//: 'Merriweather';
//breakpoints
$mq-breakpoints: (
        mobile:  320px,
        mobileLandscape: 480px,
        lg-mobile: 576px,
        tablet:  768px,
        desktop: 992px,
        lg-desktop: 1200px,
        wide: 1400px,//extra large(xxl)
);

$border: 2px solid #e5e7eb;
$overlay: rgba(112, 112, 112, 0.64);

//shadow
$evzt-default-shadow: 1px 4px 6px 3px rgba(0, 0, 0, 0.05);