
.contact-me{
    width: 100%;
    text-align: center;
}

.evzt-form-contact {
  width: 100%;
  display: flex;
  justify-content: center;
  .form-contact { 
    @include mq($from: lg-mobile){
        min-width: 550px;
    }
    margin: auto;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 10px;
    @include mq($until: lg-mobile){
        width: 100%;
        padding: 3px;
    }
    input,
    textarea {
      font-family: $fm-lato;
      width: 100%;
      padding: 18px;
      border-radius: 10px;
      border: 1px solid $dark-white;
      color: $secondary;
      margin-bottom: 10px;
      border: none;
      font-weight: 500;
      margin-top: 5px;
      &::placeholder {
        color: $secondary;
        font-size: 15px;
      }
      &:focus,
      &:active:focus {
        border: none;
        outline: none;
        box-shadow: 0 0 10px -4px $secondary;
      }
    }
    textarea {
      resize: none;
      height: 150px;
    }
    .evzt-form-contact {
      display: flex;
      flex-direction: column;
      justify-content: left;
      margin: 5px; @include mq($until: lg-mobile){
        margin: 0;
    }
    }
    .form-nameAndUsername {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @include mq($until: lg-mobile) {
        flex-direction: column;
      }
    }
  }
}

.evzt-btnSendMessage {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      #send {
        width: 100%;
        background-color: $primary;
        color: $light-white;
        border-radius: 10px;
        padding: 20px;
        font-weight: 600;
        &:hover{
          background: #06521ee5;
        }
      }
}

.contact-us-content{
  min-height: 0 !important;
}