.evzt-service{
    width: 100%;
    display: flex;
    margin: 0;
    justify-content: center;
    align-items: center;
}
.mb-2{
    margin-bottom: 20px;
}
.mt-2{
    margin-top: 20px;
}
.mt-4{
    margin-top: 30px;
}
.mt-1{
    margin-top: 10px;
}
.privancy-content-service:nth-child(odd){
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-row-gap: 15px;
    justify-content: center;
    align-items: center;
    .evzt-wedding-image{
        max-width: 450px;
        padding: 1%;
        border-radius: 10px;
        height: 350px;
        @include mq($until: lg-mobile){
            max-width: 90%;
            max-height: 350px;
        }
        @include mq($until: mobileLandscape){
            width: 100%;
            max-height: 190px;
        }
        .service-content-evzt{
            width: 100%;
            height: 100%;
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            border-radius: 8px;
            box-shadow: 0 0 20px -10px;
            padding: 1%;
            display: flex;
            background: #fff;
            &:hover{
                animation: affichage infinite;
            }
            &:nth-child(2){
                top: -100%;
                transform: rotate(5deg);
                z-index: -1;
            }
            &:nth-child(3){
                top:-200%;
                transform: rotate(10deg);
                z-index: -2;
            }
            img{
                width: 100%;
                border-radius: inherit;
            }
        }
    }
}
.evzt-service-text{
    display:flex;
    width: 100%;
    flex-direction: column;
    padding: 3%;
    margin-left:1%;
    @media (min-width: 990px) {
        max-width: 50%;

    }
}
.service-event{
    display: flex;
    justify-content: center;
    margin-bottom: 2%;
}
.privancy-content-service:nth-child(even){
    display: flex;
    flex-direction:row-reverse;
    flex-wrap: wrap;
    grid-row-gap: 15px;
    justify-content: center;
    align-items: center;
    
    .evzt-wedding-image{
        max-width: 450px;
        padding: 1%;
        border-radius: 10px;
        height: 350px;

        @media (max-width: 989px) {
           margin-top: 15px;
        }
        @include mq($until: lg-mobile){
            max-width: 90%;
            max-height: 420px;
            height: 350px;
            margin-left: -10%;
        }
        @include mq($until: mobileLandscape){
            width: 100%;
            max-height: 190px !important;
            margin-left: -10%;
        }
        .service-content-evzt{
            width: 100%;
            height: 100%;
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            border-radius: 8px;
            box-shadow: 0 0 20px -10px;
            padding: 1%;
            display: flex;
            background: #fff;
            &:nth-child(2){
                top: -100%;
                transform:translate3d(20px, -20px, 0px);
                z-index: -1;
            }
            &:nth-child(3){
                top:-200%;
                transform: translate3d(40px, -40px, 0px);
                z-index: -2;
            }
            img{
                width: 100%;
                border-radius: inherit;
            }
        }
    }
}
.content-service-animation{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    overflow: hidden;
    }
    .content-service-animation-imag{
        min-width: 30%;
        height: inherit;
        padding: 5px;
        background: #fff;
        margin-left: 10px;
        box-shadow: 0 0 20px -10px;
        border-radius: 10px;
        @include mq($until: lg-mobile){
            padding: 5px;
            border-radius: 0;
            margin-left: 0;
        }
        animation: 250s linear 1s infinite running slidein;
        @keyframes slidein {
            0%{
                transform: translateX(0px);
            }
            50%{
                transform: translateX(-810%);
            }
            100%{
                transform: translateX(0px);
            }
        }
        img{
            width: 100%;
            height: 100%;
            border-radius: inherit;
        }
    }
    
    
@media (max-width: 989px){
    .privancy-content-service{
        display:flex;
        flex-direction: column-reverse !important;
    }
}