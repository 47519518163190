.evzt-btn{
    @include transition;
    border: 0;
    border-radius: 20px;
    padding: 12px 22px;
    line-height: 1rem;
    font-family:$fm-lato;
    font-size: 1rem;
    font-weight: 500;
    display: inline-block;
    &:not(:disabled){
        cursor: pointer;
    }
    &:disabled{
        cursor: not-allowed;
    }
    &.btn-primary{
        cursor: pointer;
        background-color: $primary;
        transition: all ease-in-out 0.3;
        color: #fff;
        &:not(:disabled):hover{
            background-color: #259448;
        }
        &:disabled{
            background-color: #4ea86b;
        }
    }
    &.btn-blue{
        cursor: pointer;
        background-color: $blue;
        color: #fff;
        transition: all ease-in-out 0.3;
        &:not(:disabled):hover{
            background-color: hsl(216, 63%, 43%);
        }
        &:disabled{
            background-color: rgba($blue, 0.3);
        }
    }
    &.btn-white{
        background-color: #fff;
        box-shadow: $evzt-default-shadow;
        color: $default;
        &:disabled{
            opacity: 0.5;
         }
        &:hover{
            background-color: #fff;
            &:not(:disabled){
                color: $secondary;
            } 
        }
    }
    &-load{
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        border: 0;
        background-color: $light-white;
        padding: 5px 15px;
        border-radius: 20px;
        font-size: 14px;
        font-weight: 500;
        transition: 0.5s all linear;
        &:hover{
            opacity: 0.8;
        }
        &:focus{
            background-color: $light-white;
        }
        &.less{
            margin-left: 10px;
            color: $orange;
        }
        .material-icons{
            margin-left: 5px
        }
        &.more{
            order: -1;
            color: $secondary
        }
    }
    &.btn-icon{
        padding: 0;
    }
}

.btn-upload-image{
    @include transition;
    position: absolute;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: $blue;
    border: 0;
    width: 30px;
    height: 30px;
    cursor: pointer;
    &:hover{
        opacity: 0.8;
    }
}
//button close modal & modal title
.MuiDialogTitle-root{
    color: $secondary;
    .MuiIconButton-root{
        background-color: transparent;
    }
}