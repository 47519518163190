.abt-us-title {
    font-size:32px;
}

.abt-us-title,.articles-date {
    color: $pink;
}

@include mq($from: tablet){
    .abt-plan-title {
        margin-bottom: 4%;
    }
}

.about-plan-container {
    display: flex;
    flex-wrap: wrap;
    grid-row-gap: 15px;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    #abtPlanText {
        display:flex;
        width: 100%;
        flex-direction: column;
        padding: 2%;
        margin-left:1%;

        @media (min-width: 990px) {
            max-width: 50%;

        }
    }

    #abtPlanImages {
        display: flex;
        overflow: hidden;
        padding: 0 !important;
        max-width: 450px;

        .abt-min-width {
            width: 100%;
        }

        @include mq($until: lg-mobile){
            max-width: 90% !important;

        }
        @include mq($until: mobileLandscape){
            width: 100% !important;
            .abt-cadre{
                height: 85%;
            }
        }

        .abt-images-slide {
            padding: 10px;
            animation: scroll 10s infinite;
            width: 100%;
        }

        @keyframes scroll {
            0% {
                transform: translateX(0);
            }

            20% {
                transform: translateX(0);
            }

            40% {
                transform: translateX(-100%);
            }

            60% {
                transform: translateX(-100%);
            }

            80% {
                transform: translateX(-200%);
            }

            100% {
                transform: translateX(-200%);
            }
        }
    }
}

.about-user-reviews-box {
    .abt-user-reviews {
        display: flex;
        overflow: hidden;
        margin: auto;

        .abt-reviews-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: auto;
            padding: 5px;
            width: 100%;
            animation: scroll 20s infinite;

            p {
                font-size: 16px;
            }

            .abt-user-review-profile {
                width: 100px;
                height: 100px;

                img {
                    border-radius: 50px;
                }
            }
        }
    }
}

.about-articles {
    .abt-articles-images {
        display: flex;
        flex-wrap: wrap;
        margin: auto -34px;
        padding: 15px;

        .abt-articles {
            margin: auto;
            @include mq($from:  lg-mobile){
                width: calc((100% - 10%)/3);
            }
            @include mq($until:  lg-mobile){
                width: 100%;
            }
        }
    }

    #abtMoreBtn {
        width: auto;
        padding: 0 35px 0 35px;
    }
}

.about-contact {
    margin-top: 20px;
    background: url(../../image/wedding-organisation.jpg);
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    #abtSendBtn{
        width: auto;
        margin: 20px;
        padding: 0 35px 0 35px;
    }
}