@import "font";
@import "~sass-mq";
@import "variable";
@import "mixins";
@import "buttons";
@import "blocks/search-blocks";
@import "blocks/form";
@import "blocks/card";
@import "blocks/dropzone";
@import "pages/event";
@import "pages/profile";
@import "pages/confirm-event";
@import "pages/privacy";
@import "pages/home";
@import "pages/not-found";
@import "pages/contact-us";

@import "pages/service";
@import "pages/about";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

html,
body {
  overflow-x: hidden;
}

body {
  font-family: $fm-lato;
  font-size: 19px;
  line-height: 26px;
  position: relative;
  min-height: 100%;
  color: $secondary;
  background-color: $dark-white;

  @media (max-width: 991px) {
    font-size: 22px;
  }
}

ul {
  list-style: none;
}

@for $i from 1 through 3 {

  h#{$i},
  .h#{$i} {
    line-height: 71px;
  }
}

//-----------------------Start global class------------------//
.content-top {
  margin-top: 20px;
  margin-bottom: 30px;
}

.evzt-text-primary {
  color: $primary;
}

.evzt-text-success {
  color: $primary;
}

.evzt-text-blue {
  color: $blue;
}

.evzt-text-white {
  color: $light-white;
}

.evzt-text-secondary {
  color: $secondary;
}

.evzt-text-default {
  color: $default;
}

.evzt-text-warning {
  color: $orange;
}

.evzt-text-danger {
  color: $danger;
}

/**Position**/
.evzt-pos-bottom {
  position: absolute;
  bottom: 0 !important;
}

.evzt-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  .evzt-bottom-newsletter {
    text-align: center;
  }

  @include mq($from: wide) {
    max-width: 1320px;
  }

  @include mq($until: wide) {
    max-width: 1140px;
  }

  @include mq($until: desktop) {
    max-width: 960px;
  }

  @include mq($until: tablet) {
    max-width: 720px;
  }

  @include mq($until: lg-mobile) {
    max-width: 540px;

    .evzt-container {
      .content-top {
        text-align: left;
      }
    }
  }

  @include mq($until: tablet) {
    .evzt-bottom-newsletter {
      max-width: 420px;
      margin: 0 auto 35px;

      label {
        position: static;
        text-align: center;
        max-width: 100%;
        width: 100%;
        font-size: 22px;
        font-weight: 700;
        transform: none;
        margin-top: 5%;
      }
   } 
    .evzt-container {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
      text-align: center;

      input {
        width: 100%;
      }
    }

    .link-list {
      display: flex;
      justify-content: center;
      margin: 0;
      flex-wrap: wrap;

      &>li {
        padding: 0 10px;

        @media (max-width: 991px) {
          margin-bottom: 20px;
        }
      }

      a {
        text-decoration: none !important;
        font-weight: 600;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}

.evzt-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  // margin-right: -15px;
  // margin-left: -15px;
  margin-bottom: 30px;
  grid-row-gap: 15px;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
    margin-top: 30px;
  }
}

.load-more {
  width: 100%;
  text-align: center;
}

.logoutButton{
  color: $danger !important;
  @media (max-width: 767px) {
    font-size: 17px;
  }
}
.import-Photos-gallery{
  margin: 10px 0 0;
  @media (max-width: 774px) {
    margin-left: calc(100% - 186px) !important;
    margin-top: 18px;
  }
  
}

.evzt-form-lg-4 {
  flex: 0 0 37.5%;
  max-width: 37.5%;
  width: 100%;
  padding: 15px;
  @include mq($until: tablet){
      min-width: 100%;
  }
}

.evzt-form-lg-3{
  flex: 0 0 30;
  max-width: 30%;
  width: 100%;
  padding: 15px;
  @include mq($until: tablet){
      min-width: 100%;
  }
}

.evzt-12 {
  width: 100%;
  padding: 15px;
  @include mq($until: tablet){
    padding: 0;
  }
}

.evzt-lg-4 {
  flex: 0 0 25%;
  max-width: 25%;
  width: 100%;
  padding: 15px;
}

.evzt-lg-6 {
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;
  padding: 15px;
}

.evzt-lg-8 {
  flex: 0 0 75%;
  max-width: 75%;
  width: 100%;
  // padding: 15px;
  h2{
    margin: 10px 10px 10px 0;
  }
}

.evzt-p15 {
  padding: 15px;
}

.evzt-prl15 {
  padding-left: 15px;
  padding-right: 15px;
}

.evzt-mb15 {
  margin-bottom: 15px;
}

.evzt-text-center {
  text-align: center;
}

.evzt-text-left {
  text-align: left;
}

.evzt-text-right {
  text-align: right;
}

.block-large-notification {
  width: 100%;
  margin-top: 42px;

  div {
    background-color: #fff;
    text-align: center;
    padding: 15px 0;
    font-size: 16px;
    font-weight: 700;
    color: $default;
  }
}

.collapsed {
  height: 0;
  overflow: hidden;
}

.app-content {
  display: grid;
  grid-template-columns: minmax(0, 225px) minmax(0, auto);
  grid-column-gap: 30px;
  margin-top: 50px;
  margin-bottom: 50px;

  .app-left-side {
    display: flex;
    flex-direction: column;

    a {
      color: $secondary;
      text-decoration: none;
    }

    .profile {
      background-color: #fff;
      padding: 30px 0 0;
      border-radius: 5px;

      .avatar {
        display: block;
        height: 92px;
        width: 92px;
        border-radius: 50%;
        margin-right: auto;
        margin-left: auto;
      }

      .profile-full-name {
        font-size: 18px;
        font-weight: 700;
        margin: 20px 0;
        text-align: center;

        @media (max-width: 991px) {
          margin: 15px 0 20px;
        }
      }

      .profile-action {
        display: flex;
        justify-content: space-around;
        margin-bottom: 15px;

        div {
          a {
            display: flex;
            flex-direction: column;
            align-items: center;

            strong {
              font-weight: 500;
              font-size: 15px;
              text-decoration: underline;
              @include transition;

              &:hover {
                opacity: 0.5;
              }
            }

            @media (max-width: 991px) {
              flex-direction: row-reverse;
              grid-column-gap: 10px;
              font-weight: 500;
            }
          }
        }

        @media (max-width: 991px) {
          grid-column-gap: 25px;
          justify-content: center;
        }
      }

      .profile-about {
        .notifier {
          top: -2px;
          left: unset;
          right: -10px;
        }

        li {
          font-weight: 500;

          a {
            display: inline-flex;
            padding: 10px 15px;

            svg {
              margin-right: 10px;
              height: 16px;
            }
          }

          &.under-list-parent {
            cursor: pointer;
            padding: 10px 15px;

            .title {
              display: inline-flex;

              span {
                flex: 1;
              }

              svg {
                height: 16px;

                &:first-child {
                  margin-right: 10px;
                }
              }
            }
          }

          ul {
            @include transition(height);
            padding-left: 25px;
          }
        }
      }
    }

    .contact {
      background-color: #fff;
      margin-top: 30px;
      border-radius: 5px;
      padding-bottom: 15px;

      .load-more {
        display: block;
        padding: 15px;

        &:hover {
          @include transition;
          opacity: 0.6;
        }
      }

      h2 {
        font-size: 18px;
        font-weight: 600;
        padding: 10px 15px;
      }

      ul {
        li {
          border-bottom: $border;

          &:last-child {
            border-bottom: 0;
          }

          a {
            padding: 15px;
            display: flex;
            align-items: center;
            overflow: hidden;

            &:hover {
              background-color: $dark-white;
            }

            img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              margin-right: 15px;
              aspect-ratio: 50 / 50;
            }

            div {
              display: flex;
              flex-direction: column;
              overflow: hidden;

              .contact-row-fullname {
                font-size: 16px;
                font-weight: 700;
                display: inline-block;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                max-width: 100%;
              }

              small {
                display: inline-block;
                max-width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }
          }
        }
      }
    }

    .logout-wrapper {
      padding: 15px 17px;
    }
  }

  @media (max-width: 991px) {
    grid-template-columns: 100%;
  }
}

.evzt-d-none {
  display: none !important;
}

.divider {
  height: 1px;
  padding: 0;
  background-color: #E5E7EB;
  margin: 5px 0;
}

//-----------------------style events list------------------//
.event-filter {
  .evzt-input-group {
    flex: 0 0 66.66%;
    max-width: 66.66%;
    padding-right: 15px;
  }

  .select-wrapper {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    position: relative;

    @media (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  form {
    &>div {
      display: flex;
      justify-content: right;
    }
  }
}

.event-list-container {
  margin-top: 30px;
}

//-----------------------lazyloading------------------//
.loading-line-state {
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: $dark-white;
    animation-name: line-animation;
    animation-timing-function: linear;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    opacity: 0.4;
  }
}

.loading-image-state {
  animation-name: image-animation;
  animation-timing-function: linear;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes image-animation {
  0% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

@keyframes line-animation {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}


.vertical-loading-content {
  display: flex;
  margin-bottom: 5px;
  padding: 15px;
  opacity: 0.3;

  .avatar-loading {
    @extend .loading-image-state;
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background-color: $default;
    border-radius: 50%;
    margin-right: 10px;
  }

  .description-loading {
    display: flex;
    flex-direction: column;
    width: 100%;

    span {
      @extend .loading-line-state;
      content: "";
      display: block;
      background-color: $default;
      border-radius: 2px;
      height: 1px;
      min-width: 100%;
      width: 100%;
      margin-bottom: 10px;
      position: relative;

      &:first-child {
        height: 3px;
      }
    }
  }
}

.horizontal-loading {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  padding: 15px;
  border-radius: 5px;
  background: #3aa65c1f;
  @include mq($until: tablet){
    flex: 1;
    max-width: 100%;
  }

  .horizontal-loading-content {
    display: flex;
    flex-direction: column;
    background-color: $dark-white;
    border-radius: 10px;

    .horizontal-loading-image {
      content: "";
      display: block;
      background-color: $default;
      height: 125px;
      margin-bottom: 5px;
      border-radius: 5px 5px 0 0;
      @extend .loading-image-state
    }

    .horizontal-loading-line {
      display: flex;
      flex-direction: column;
      padding: 15px;
      position: relative;

      span {
        @extend .loading-line-state;
        content: "";
        display: block;
        background-color: $default;
        height: 1px;
        margin-bottom: 5px;

        &:first-child {
          margin-bottom: 10px;
        }

        &:nth-child(2) {
          height: 3px;
          margin-bottom: 20px;
        }

      }
    }

  }
}

.evzt-po-relative {
  position: relative;
}

.notifier {
  position: absolute;
  border-radius: 10px;
  box-shadow: 1px 1px 5px hsl(239, 22%, 38%);
  background: $danger;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  min-height: 19px;
  min-width: 19px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  line-height: 0;
  text-shadow: none;
  position: relative;
  top: 0px;
  left: 0;
}

.btn-load-more {
  font-size: 14px;
  padding: 15px 8px;
  display: inline-block;
  box-shadow: 1px 4px 6px 3px rgba(0, 0, 0, 0.05);
  background-color: #fff;
}

.logout {
  display: flex;
  align-items: center;
  grid-column-gap: 35px;
  width: 100%;
  font-weight: 700;
  font-size: 1rem;

  svg {
    height: 20px;
  }
}

.MuiPaper-root {
  &.MuiAlert-root {
    position: fixed;
    top: 75px;
    right: 25px;
    z-index: 9;
    @include transition(0.7s)
  }
}

.evzt-bg-white {
  background-color: #fff;
  padding: 30px;

  @include mq($until: lg-mobile){ 
  padding: 0;
  padding-right: 0;
  padding-left: 0;
  text-align: left;
  
  }
}


.p-4 {
  padding: 1.5rem !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.evzt-table {
  width: 100%;

  .evzt-table-actions {
    display: flex;
    justify-content: flex-end;

    @include mq($until: lg-mobile){ 

      .evzt-icon {
        font-size: 35px;
      }

    }
  }

  @include mq($until: lg-mobile){ 

    .evzt-icon {
      font-size: 35px;
    }
  }

  thead {
    th {
      border-bottom: none;
    }
  }

  td,
  th {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
  }
}

/* guest-dashboard-info-box */
.guests {
  .list-ct {
    .thumb {
      margin-right: 20px;

      img {
        width: 80px;
        height: 80px;
        -o-object-fit: cover;
        object-fit: cover;
        overflow: hidden;
        border-radius: 50%;

        @include mq($until: lg-mobile) {
          width: 40px;
          height: 40px;
        }
      }
    }

    td {
      vertical-align: middle;

      li {
        margin: 0 4px;
      }
    }
  }

  .list-id {
    color: $default;
    font-weight: 600;
  }

  .title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 30px 0;
  }

  .evzt-table {
    thead {
      th {
        border: 0;
        font-size: 18px;
      }
    }

    td {
      padding: 0.75rem;
      border-top: 1px solid #dee2e6;
    }

    .guest-list-info {
      .guest-list-title {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 8px;

        a {
          color: $blue;
          text-decoration: none;

          @include mq($until: lg-mobile){ 
            font-size: 16px;
          }
        }
      }
    }

    .guest-state-value {
      .material-icons {
        vertical-align: middle;
        margin-right: 5px;
      }
    }
  }
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: 500px;
  max-width: 500px;
}

.MuiDialogContent-root {
  padding: 25px 15px;
}

.MuiDialogTitle-root+.css-ypiqx9-MuiDialogContent-root {
  padding-top: 10px;
}

.css-cveggr-MuiListItemIcon-root {
  min-width: 34px;
}

.css-h4y409-MuiList-root {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

//avatar
.circle-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  aspect-ratio: 50 / 50;
}

.contact-image {
  img {
    width: 150px;
    height: auto;
  }
}

.evzt-contact-modal-container { 
  padding: 6px;

  .evzt-border-wrapper {
    border: 1px solid #3e407e;
    border-radius: 5px;
  }

}

.contact-modal {

  .css-1fu2e3p-MuiPaper-root-MuiDialog-paper {
    width: 100%;
    max-width: 420px;
    max-height: unset;

    @media (max-width: 480px) {
      max-width: 100%;
    }
  }

  .contact-item {
    display: flex;
    align-items: center;
    grid-column-gap: 8px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    label {
      color: $default;
    }

    svg {
      fill: $secondary;
      max-width: 20px;
    }
  }

  .contact-modal-header {
    height: 27px;

    &>.btn-close {
      float: right;
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
      background-color: transparent;
      outline: 0px;
      border: 0px;
      margin: 0px;
      cursor: pointer;
      -webkit-user-select: none;
      user-select: none;
      appearance: none;
      text-decoration: none;
      text-align: center;
      font-size: 1.5rem;
      padding: 8px;
      border-radius: 50%;
      overflow: visible;
      transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      position: absolute;
      right: 8px;
      top: 8px;
      color: rgb(158, 158, 158);

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }

  .MuiDialogContent-root {
    display: flex;
    grid-gap: 0 15px;
    padding: 20px 40px 16px 16px !important;
    overflow: hidden;

    .title {
      // margin-bottom: 10px;
    }

    @media (max-width: 767px) {
      padding: 16px !important;
    }

    @media (max-width: 480px) {
      flex-direction: column;
      grid-gap: 15px 0;
    }
  }
}

//Modal dropzone
.modal-event-dropzone {
  .MuiPaper-root.css-uhb5lp {
    max-width: 500px;
  }

  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    @media (max-width: 575px) {
      margin: 32px 15px;
    }
  }

  .MuiListItemIcon-root {
    &.css-jkf7z6-MuiListItemIcon-root {
      @media (max-width: 575px) {
        width: 100%;
        margin: 0;
      }
    }
  }

  .MuiListItem-root {
    &.css-1tbmf5u-MuiListItem-root {
      @media (max-width: 575px) {
        flex-wrap: wrap;
      }
    }
  }

  .css-10hburv-MuiTypography-root {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.reset-ct {
  margin-top: 20px;
  margin-bottom: 20px;
}

.evzt-block-logo {
  margin: auto;
  padding: 20px;
}

//About-page
.abt-images-slide,
.abt-img {
  width: 100%;
  height: 100%;
}

.abt-min-width {
  min-width: 100%;
}

.abt-cadre {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  background: transparent;
  border: $border ;
}

.abt-reviews-title,
.abt-articles-title {
  padding: 40px;
}

.abt-btn {
  background:$primary;
  color: $dark-white;
  border-radius:0;
  height: 60px;

  &:hover {
    background:$pink ;
  }
}

.loader-data-block {
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.profile-action-content,
.load-more{
  font-size:16px;
}