.not-found{
    max-width: 600px;
    margin: 0 auto;
    padding: 150px 0;
    .not-found-status{
        text-align: center;
        margin-bottom: 20px;
        font-size: 75px;
        line-height: 70px;
    }
    &-title{
        font-size: 32px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 15px;
        line-height: 25px;
    }
    p:not(.link-home-wrapper){
        font-size: 18px;
        margin-bottom: 12px;
        line-height: 22px;
    }
    a{
        &.evzt-btn{
            display: inline-block;
            text-decoration: none;
            font-weight: 500;
            margin: 25px 0 0;
        }
    }
}