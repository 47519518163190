.pub-footer {
  padding: 65px 0;
  color: $light-white;
  background-color: #06521e;
  min-height: calc(100vh);

  .link-list a {
    color: $light-white;
  }

  @include mq($from: tablet) {
    .evzt-bottom-newsletter {
      max-width: 420px;
      margin: 0 auto 35px;

      label {
        position: static;
        text-align: left;
        max-width: 100%;
        width: 100%;
        font-size: 16px;
        font-weight: 700;
        transform: none;
      }
    }

    .link-list {
      display: flex;
      justify-content: center;
      margin: 0;
      flex-wrap: wrap;
      list-style: none;

      &>li {
        padding: 0 10px;

        @media (max-width: 991px) {
          margin-bottom: 20px;
        }
      }

      a {
        text-decoration: none;
        font-weight: 500;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }

  .evzt-language-menu {
    margin: 0 0 35px auto;
    width: 200px;
    .evzt-lang-label {
      font-size: 17px;
      margin-bottom: 5px;
      display: inline-block;
      font-weight: 700;
    }
    @include mq($until: desktop){
      margin: 0 auto 35px;
      text-align: center;
    }
  }

  .evzt-copy-right {
    margin-top: 35px;
  }

  .lang-selector {
    color: $light-white;
    font-size: 16px;
    margin: 0 -5px;
  }

  .lang {
    display: inline-block;

    &:not(.active) {
      img {
        filter: grayscale(100);
      }
    }

    &:not(:first-child) {
      margin-left: 2px;
    }

    button {
      padding: 5px;
      border: 0;
      cursor: pointer;
    }

    &-flag {
      &>img {
        width: 25px;
      }
    }

    &-label {
      display: inline-block;
      width: 0;
      overflow: hidden;
      opacity: 0;
    }
  }
}

#privateFooter,#privateMobileFooter {
  padding-top: 35px;
  border-top: solid 0.2px #ecebe7;
  margin-top: auto;
  a{
    color: inherit;
    text-decoration: inherit;
  }
}
#privateMobileFooter{
  text-align: center;
  margin-bottom: -20px;
  .evzt-copy-right{
    margin-top: 20px;
    font-size: smaller;
  }
  .link-list > li {
    margin-bottom: 5px;
  }

  .link-list {
    flex-direction: column;
    align-items: center;
  }
}