@import "../variable";
@import "../mixins";
.header{
  position: -webkit-sticky;
  position: sticky;
  padding: 16px 25px;
  top: 0;
  right: 0;
  left: 0;
  color: #fff;
  background-color: #fff;
  box-shadow: 0 0 20px -15px #000;
  z-index: 9;
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-brand{
      font-size: 1.15rem;
      font-weight: 700;
      color: #fff;
      display: flex;
      align-items: center;
      text-decoration: none;
      img{
        width: 50px;
      }
      .header-brand-text{
        margin-left: 8px;
      }
    }
    .header-right-side {
      display: flex;
      align-items: center;
      .header-notification {
        margin-right: 40px;
        position: relative;
        svg{
          height: 18px;
          display: inline-block;
          vertical-align: middle;
        }
        .notifier{
          top: -12px;
          left: -2px;
        }
      }
      .header-app-user {
        position: relative;
        display: flex;
        align-items: center;
        .header-app-user-avatar {
          cursor: pointer;
          display: flex;
          align-items: center;
          img {
            height: 45px;
            width: 45px;
            border-radius: 50%;
          }
        }

        .header-user-menu {
          position: absolute;
          top: 45px;
          right: 0;
          padding-top: 10px;
          padding-bottom: 5px;
          min-width: 200px;
          max-width: 200px;
          background: #fff;
          box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.16);
          border-radius: 5px;
          @media (max-width: 768px) {
            right: -25px;
          }
          .mobile-display{
            @media (min-width: 768px){
              display: none;
            }
          }
          .header-user-menu-profile{
            padding: 10px 15px 5px 15px;
            span{
              display: block;
              font-weight: 700;
              line-height: 0.5rem;
              @media (max-width: 768px) {
                font-size: 17px;
                font-weight: 700;
              }
            }
          }
          .h-app-user-logout {
            svg{
              width: 16px;
              display: inline-block;
              vertical-align: middle;
              margin-right: 15px;
            }
          }
          a,.link{
            display: block;
            padding: 5px 15px;
            text-decoration: none;
            font-weight: 500;
            color: $secondary;
            word-wrap: break-word;
            @media (max-width: 767px) {
              font-size: 17px;
              font-weight: 500;
            }
            &:hover{
              background-color: #F8F8FB;
            }
          }
          .link {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.evzt-theme-primary{
  .header{
    background-color: $primary;
  }
}
ul.menu-list{
  list-style: none;
  float: right;
  display: flex;
li a{
  text-decoration: none;
  color: $light-white;
  padding: 10px 20px;
  text-transform: uppercase;
  border: 1px solid transparent;
  margin: 5px;
  font-weight: 500;
  transition: 0.6s ease;
  border-radius: 30px;
  font-size: 16px;
}
a:hover{
  background: $light-white;
  color:$primary;
}
.active a{
  background: $light-white;
  color:$primary
}
}
#open-menu{
  display: none;
  border: none;
  cursor: pointer;
}
#def{
  display: none;
}
button{
  background: transparent;
}
@media (max-width: 992px) {
  #open-menu{
    display: block;
  }
  ul.menu-list{
    display: none;
  }
}
#evzt-logo{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  #image-logo{
    width: 80px;
    img{
      width: 100%;
    }
  }
}
#closedme{
  border: none;
  button{
    border: none;
  }
}
#nav-menu{
  width: 100%;
  margin-top: 10px !important;
  display: flex;
  flex-direction: column;
}
#def{
  position: fixed;
  top: 0;
  background: $default;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  z-index: 1;
  > #evzt-menu{
    background: transparent;
    min-width: 100%;
    min-height: 100%;
    position: relative;
    z-index: 2;
    overflow-y: auto;
    margin: 0;
    padding-bottom: 40px;
    height: 1px;
    box-sizing: border-box;
    .header-res{
      width: 100%;
      margin-top: 20px;
      text-decoration: none;
      li{
        font-size: larger;
        margin-top: 35px;
        text-decoration: none;
        padding-bottom: 1%;
        width: 100%;
        border-block-end: $light-white 2px solid;
      }
      li > a{
        color: $light-white;
        text-decoration: none;
      }
      #closedmenue svg {
        stroke: $secondary
      }
    }
  }
}  
