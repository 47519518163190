@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Lato-Light.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/Lato-Black.ttf') format('truetype');
}

// Other
