@mixin transition($prop: all, $duration: 0.3s, $tm-function: ease-in-out) {
  -webkit-transition: $prop $duration $tm-function;
  -moz-transition: $prop $duration $tm-function;
  -ms-transition: $prop $duration $tm-function;
  -o-transition: $prop $duration $tm-function;
  transition: $prop $duration $tm-function;
}

@mixin input-focus($color: "primary"){
  @include transition(box-shadow, 0.15s);
  &:focus {
    outline: none;
    @if $color == "primary" {
      box-shadow: 0 0 0 3px hsla(140deg, 45%, 52%, 0.3%);
    }@else {
      box-shadow: 0 0 0 3px hsla(238deg, 34%, 37%, 0.3%);
    }
  }
}