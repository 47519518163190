.upload-area {
    margin-top: 10%;

    .thumb {
        margin-top: -40px;
        margin-left: 70px;
        rotate: 320deg;
        @include mq($until: tablet){
            position: absolute;
            bottom: -40px;
            left: -70px;
        }
    }

    .upload-area-content {
        margin-right: 50px;

        .image {
            margin-bottom: 15px;
            border: $border;
            position: relative;
            width: 200px;
            height: 200px;
            max-width: 200px;
            max-height: 200px;

            @include mq($until: lg-mobile) {
                max-width: 250px;
                width: 100%;
                margin: auto;
            }

            &>img {
                width: 100%;
                max-width: 100%;
            }

            &>.btn-upload-image {
                left: 5px;
                bottom: 5px;
            }
        }

        .yellow-cross {
            right: -30px;
            bottom: 20px;
            @include mq($until: tablet){
                bottom: -50px;
            }
            
        }
    }
}

.mtb50 {
    margin-top: 10%;
    margin-bottom: 10%;
}

.confirm-abs {
    position: absolute;
}

.confirm-index {
    z-index: -1;
}

.tuto-area {
    @include mq($until: desktop) {
        justify-content: center !important;
    }

    .top-left-content {
        .tuto {
            z-index: 1;
        }

        .bulb {
            left: 20px;
            top: -55px;
        }

        .discs {
            right: -40px;
            bottom: -40px;
        }
    }

    .top-right-content {
        @include mq($from: desktop) {
            max-width: 50%;
        }

        @include mq($until: desktop) {
            max-width: 700px;
            width: 90%;
            margin-top: 25px;
        }

        .tuto-desc {
            ul {
                list-style: disc;
            }
        }

        .gray-cross {
            right: 0;
            top: -50px;

            @include mq($until: desktop) {
                top: -20px;
            }
        }

        .envelop {
            right: 0;
        }
    }
}