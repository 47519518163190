.profile-top-banner{
    width: 100%;
    height: 208px;
    background-color: black;
    color: #fff;
    position: relative;
    margin-top: 65px;
    .profile-avatar{
        width: 130px;
        height: 130px;
        position: absolute;
        bottom: -65px;
        left: 50%;
        transform: translateX(-50%);
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 50%;
            border: $border
        }
        .btn-upload-image{
            right: 0;
            bottom: 5px;
        }
        .avatar-file{
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }
    }
    .username{
        position: absolute;
        bottom: 10px;
        font-size: 15px;
        font-weight: 500;
        color: #fff;
        left: 0;
        padding-left: 85px;
        padding-right: 75px;
        margin: 0;
        @include mq($until: tablet){
            padding-left: 10px;
            display: flex;
            width: 35%;
            flex-wrap: wrap;
            padding-right: 10px;
        }
    }
    }
    .pr-infos{
        position: absolute;
        padding-right: 85px;
        padding-left: 75px;
        right: 0;
        font-size: 15px;
        font-weight: 400;
        color: #fff;
        bottom: 10px;
        margin: 0;
        display: flex;
        justify-content: flex-end;
        grid-column-gap: 8px;
        strong, b{
            font-weight: 700;
        }
        @include mq($until: tablet){
            padding-left: 10px;
            padding-right: 10px;
        }
        @include mq($until: lg-mobile){
            display: flex;
            flex-direction: column;
        }
    }
.profile-form{
    max-width: 882px;
    margin: 120px auto 0;
    @include mq($until : tablet){
        display: grid;
        grid-template-columns: 1fr;
        .evzt-form-row{
            display: grid;
            grid-template-columns: 1fr;
        }
    }
    
}