//Only need to style the form and the inputs
@import "../variable";
@import "../mixins";

.evzt-form-row {
  display: flex;
  grid-column-gap: 15px;
}

.evzt-submit {
  margin-top: 25px;
  margin-bottom: 30px;
}

.evzt-form-title {
  margin-bottom: 22px;
  color: $secondary;
  font-size: 18px;
  font-weight: 600;
  display: block;
  width: 100%;
}

.event-form-content {

  .evzt-form-lg-3 {
    .customized-checkbox-block {
      position: relative;
      display: block;
      padding-left: 2rem;
      line-height: 2rem;
      left: 0;
    }
  }
}

.evzt-form-group {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;

  @include mq($until: tablet) {
    width: 99%;
    padding-left: 1%;
  }

  &::after {
    display: block;
    clear: both;
    content: "";
  }

  label {
    margin-bottom: 8px;
    font-weight: 500;
  }

  input::placeholder,
  textarea::placeholder {
    color: hsla(236, 20, 79, 100%);
  }

  input,
  textarea,
  select {
    @include input-focus("secondary");
  }



  input[type=checkbox] {
    position: absolute;
    z-index: 10;
    /* opacity: 0; */
    left: 0;
    width: 55%;
    height: 100%;
    cursor: pointer;
  }
}

.evzt-input-form {
  display: block;
  width: 100%;
  padding: 15px;
  font-size: 16px;
  background-color: $dark-white;
  border: thin solid #114e0321;
  border-radius: 5px;
  font-family: $fm-lato;
}

.evzt-input-control {
  display: block;
  width: 100%;
  padding: 15px;
  font-size: 16px;
  color: #495057;
  background-color: #fff;
  border: $border;
  border-radius: 3px;
}

.evzt-custom-select {
  border: $border;
  border-radius: 3px;
  color: $secondary;
  width: 100%;
  padding: 15px 35px 15px 15px;
  font-family: $fm-lato;
  font-size: 0.875rem;
  font-weight: 500;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='20px' viewBox='0 0 420 512'><path d='M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z' style='fill: rgb(143, 145, 172);'></path></svg>") no-repeat;
  background-position: right 10px center;
  background-size: 20px;
  @include input-focus("secondary");
}

/**************************** start input append group *****************************/
.evzt-input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.append-group {

  select:not(:last-child),
  .evzt-input-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
  }
}

.evzt-append-group {
  display: -ms-flexbox;
  display: flex;
  margin-left: -1px;

  .btn-search {
    width: 80px;
    cursor: pointer;
    border-radius: 0 3px 3px 0;
    border: 0;
  }
}

/**************************** end input append group *****************************/
.customized-checkbox-block {
  position: relative;
  display: block;
  padding-left: 2rem;
  line-height: 2rem;
  left: 25%;

  input[type=checkbox] {
    position: absolute;
    z-index: 1;
    opacity: 0;
    left: 0;
    width: 55%;
    height: 100%;
    cursor: pointer;

    &:checked~.custom-label::before {
      background-color: $primary;
      border-color: $primary;
    }

    &:checked~.custom-label::after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjaGVjayIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWNoZWNrIGZhLXctMTYiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBkPSJNMTczLjg5OCA0MzkuNDA0bC0xNjYuNC0xNjYuNGMtOS45OTctOS45OTctOS45OTctMjYuMjA2IDAtMzYuMjA0bDM2LjIwMy0zNi4yMDRjOS45OTctOS45OTggMjYuMjA3LTkuOTk4IDM2LjIwNCAwTDE5MiAzMTIuNjkgNDMyLjA5NSA3Mi41OTZjOS45OTctOS45OTcgMjYuMjA3LTkuOTk3IDM2LjIwNCAwbDM2LjIwMyAzNi4yMDRjOS45OTcgOS45OTcgOS45OTcgMjYuMjA2IDAgMzYuMjA0bC0yOTQuNCAyOTQuNDAxYy05Ljk5OCA5Ljk5Ny0yNi4yMDcgOS45OTctMzYuMjA0LS4wMDF6Ij48L3BhdGg+PC9zdmc+");
      filter: invert(1);
    }
  }

  .custom-label {
    cursor: pointer;
  }

  .custom-label::before {
    position: absolute;
    top: 0.3rem;
    left: 0;
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 0.25rem;
    pointer-events: none;
    content: "";
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid #ded8d8e3;
  }

  .custom-label::after {
    position: absolute;
    top: 0.2rem;
    left: 0;
    display: block;
    width: 22px;
    height: 22px;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    border: 1px solid transparent;
  }
}

.input-password-block {
  position: relative;

  .icon-password {
    content: "";
    position: absolute;
    display: inline-block;
    right: 0;
    top: 50%;
    width: 40px;
    height: 40px;
    margin-right: 2px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    cursor: pointer;
    transform: translateY(-50%);

    &.icon-eye {
      background-image: url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhciIgZGF0YS1pY29uPSJleWUiIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS1leWUgZmEtdy0xOCIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NzYgNTEyIj48cGF0aCBmaWxsPSJjdXJyZW50Q29sb3IiIGQ9Ik0yODggMTQ0YTExMC45NCAxMTAuOTQgMCAwIDAtMzEuMjQgNSA1NS40IDU1LjQgMCAwIDEgNy4yNCAyNyA1NiA1NiAwIDAgMS01NiA1NiA1NS40IDU1LjQgMCAwIDEtMjctNy4yNEExMTEuNzEgMTExLjcxIDAgMSAwIDI4OCAxNDR6bTI4NC41MiA5Ny40QzUxOC4yOSAxMzUuNTkgNDEwLjkzIDY0IDI4OCA2NFM1Ny42OCAxMzUuNjQgMy40OCAyNDEuNDFhMzIuMzUgMzIuMzUgMCAwIDAgMCAyOS4xOUM1Ny43MSAzNzYuNDEgMTY1LjA3IDQ0OCAyODggNDQ4czIzMC4zMi03MS42NCAyODQuNTItMTc3LjQxYTMyLjM1IDMyLjM1IDAgMCAwIDAtMjkuMTl6TTI4OCA0MDBjLTk4LjY1IDAtMTg5LjA5LTU1LTIzNy45My0xNDRDOTguOTEgMTY3IDE4OS4zNCAxMTIgMjg4IDExMnMxODkuMDkgNTUgMjM3LjkzIDE0NEM0NzcuMSAzNDUgMzg2LjY2IDQwMCAyODggNDAweiI+PC9wYXRoPjwvc3ZnPg==");
    }

    &.icon-eye-trash {
      background-image: url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhciIgZGF0YS1pY29uPSJleWUtc2xhc2giIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS1leWUtc2xhc2ggZmEtdy0yMCIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NDAgNTEyIj48cGF0aCBmaWxsPSJjdXJyZW50Q29sb3IiIGQ9Ik02MzQgNDcxTDM2IDMuNTFBMTYgMTYgMCAwIDAgMTMuNTEgNmwtMTAgMTIuNDlBMTYgMTYgMCAwIDAgNiA0MWw1OTggNDY3LjQ5YTE2IDE2IDAgMCAwIDIyLjQ5LTIuNDlsMTAtMTIuNDlBMTYgMTYgMCAwIDAgNjM0IDQ3MXpNMjk2Ljc5IDE0Ni40N2wxMzQuNzkgMTA1LjM4QzQyOS4zNiAxOTEuOTEgMzgwLjQ4IDE0NCAzMjAgMTQ0YTExMi4yNiAxMTIuMjYgMCAwIDAtMjMuMjEgMi40N3ptNDYuNDIgMjE5LjA3TDIwOC40MiAyNjAuMTZDMjEwLjY1IDMyMC4wOSAyNTkuNTMgMzY4IDMyMCAzNjhhMTEzIDExMyAwIDAgMCAyMy4yMS0yLjQ2ek0zMjAgMTEyYzk4LjY1IDAgMTg5LjA5IDU1IDIzNy45MyAxNDRhMjg1LjUzIDI4NS41MyAwIDAgMS00NCA2MC4ybDM3Ljc0IDI5LjVhMzMzLjcgMzMzLjcgMCAwIDAgNTIuOS03NS4xMSAzMi4zNSAzMi4zNSAwIDAgMCAwLTI5LjE5QzU1MC4yOSAxMzUuNTkgNDQyLjkzIDY0IDMyMCA2NGMtMzYuNyAwLTcxLjcxIDctMTA0LjYzIDE4LjgxbDQ2LjQxIDM2LjI5YzE4Ljk0LTQuMyAzOC4zNC03LjEgNTguMjItNy4xem0wIDI4OGMtOTguNjUgMC0xODkuMDgtNTUtMjM3LjkzLTE0NGEyODUuNDcgMjg1LjQ3IDAgMCAxIDQ0LjA1LTYwLjE5bC0zNy43NC0yOS41YTMzMy42IDMzMy42IDAgMCAwLTUyLjg5IDc1LjEgMzIuMzUgMzIuMzUgMCAwIDAgMCAyOS4xOUM4OS43MiAzNzYuNDEgMTk3LjA4IDQ0OCAzMjAgNDQ4YzM2LjcgMCA3MS43MS03LjA1IDEwNC42My0xOC44MWwtNDYuNDEtMzYuMjhDMzU5LjI4IDM5Ny4yIDMzOS44OSA0MDAgMzIwIDQwMHoiPjwvcGF0aD48L3N2Zz4=");
    }
  }
}

fieldset {
  border: $border;
  width: 100%;

  legend {
    margin-left: 30px;
    padding: 0 10px;
    font-weight: 600;
  }

  &.fieldset-form {
    padding: 0 15px;
  }

  textarea {
    min-height: 80px;
    resize: none;
  }
}

/**************************** CKEDITOR ******************************************/
.editor {
  .ck-editor {
    border: 0;

    .ck-toolbar {
      border: $border;
      border-radius: 5px;
    }
  }

  .ck-content {
    min-height: 175px;
    border: $border;
    border-radius: 0 0 5px 5px;
  }
}

/**************************** Input validation ******************************************/
.input-feedback {
  line-height: 16px;
  font-size: 14px;
  margin-top: 4px !important;
  align-items: center;
  color: rgb(250, 101, 89);
  display: flex;
  padding: 5px 25px 0px;

  &.invalid {
    color: $danger;
  }

  &.valid {
    color: $green;
  }
}

.evzt-alert {
  line-height: normal;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 15px;
  padding: 8px 15px;
  border-radius: 5px;
  text-align: center;

  svg,
  .material-icons {
    display: inline-block;
    vertical-align: middle;
  }

  &.success {
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc;
  }

  &.error {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
  }

  &.warning {
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
  }
}

.submit-load {
  svg {
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
  }
}